export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export function closestEquivalentAngle(from: number, to: number) {
	var delta = ((((to - from) % 360) + 540) % 360) - 180;
	return from + delta;
}

import shipIcon from "../components/icon/new-icons/ship/towing.svg";
import {
  bstSvg,
  bstPkkSvg,
  bstForSvg,
  bstForPkkSvg,
  cargoSvg,
  cargoPkkSvg,
  cargoForSvg,
  cargoForPkkSvg,
  fishingSvg,
  fishingPkkSvg,
  fishingForSvg,
  fishingForPkkSvg,
  highSpeedSvg,
  highSpeedPkkSvg,
  highSpeedForSvg,
  highSpeedForPkkSvg,
  militarySvg,
  militaryPkkSvg,
  militaryForSvg,
  militaryForPkkSvg,
	motionLessSvg,
	motionLessForSvg,
  passengerSvg,
  passengerPkkSvg,
  passengerForSvg,
  passengerForPkkSvg,
  rescueSvg,
  rescuePkkSvg,
  rescueForSvg,
  rescueForPkkSvg,
  specialSvg,
  specialPkkSvg,
  specialForSvg,
  specialForPkkSvg,
  tankerSvg,
  tankerPkkSvg,
  tankerForSvg,
  tankerForPkkSvg,
  towingSvg,
  towingPkkSvg,
  towingForSvg,
  towingForPkkSvg,
  tugSvg,
  tugPkkSvg,
  tugForSvg,
  tugForPkkSvg,
  unspecifiedSvg,
  unspecifiedPkkSvg,
  unspecifiedForSvg,
  unspecifiedForPkkSvg,
  wingSvg,
  wingPkkSvg,
  wingForSvg,
  wingForPkkSvg
} from "../components/icon/new-icons";

export const getShipIcon = (shipType?: string, mmsi?: string, no_pkk?: string, speed?: number) => {
	if (Number(speed) === 0) {
		if (mmsi && mmsi.slice(0, 3) === "525") {
			return motionLessSvg
		} else {
			return motionLessForSvg
		}
	}
  if (!shipType) return shipIcon;
	
	let isIndonesia = false
	if (mmsi && mmsi.slice(0, 3) === "525") {
		isIndonesia = true
	}
	let isPkk = false
	if (no_pkk) {
		isPkk = true
	}

	const shipTypes = [
		{ type: "passenger", domestic: passengerSvg, domesticPkk: passengerPkkSvg, foreign: passengerForSvg, foreignPkk: passengerForPkkSvg },
		{ type: "fishing", domestic: fishingSvg, domesticPkk: fishingPkkSvg, foreign: fishingForSvg, foreignPkk: fishingForPkkSvg },
		{ type: "tanker", domestic: tankerSvg, domesticPkk: tankerPkkSvg, foreign: tankerForSvg, foreignPkk: tankerForPkkSvg },
		{ type: "cargo", domestic: cargoSvg, domesticPkk: cargoPkkSvg, foreign: cargoForSvg, foreignPkk: cargoForPkkSvg },
		{ type: "rescue", domestic: rescueSvg, domesticPkk: rescuePkkSvg, foreign: rescueForSvg, foreignPkk: rescueForPkkSvg },
		{ type: "tug", domestic: tugSvg, domesticPkk: tugPkkSvg, foreign: tugForSvg, foreignPkk: tugForPkkSvg },
		{ type: "military", domestic: militarySvg, domesticPkk: militaryPkkSvg, foreign: militaryForSvg, foreignPkk: militaryForPkkSvg },
		{ type: "towing", domestic: towingSvg, domesticPkk: towingPkkSvg, foreign: towingForSvg, foreignPkk: towingForPkkSvg },
		{ type: "high-speed", domestic: highSpeedSvg, domesticPkk: highSpeedPkkSvg, foreign: highSpeedForSvg, foreignPkk: highSpeedForPkkSvg },
		{ type: "unspecified reserved", domestic: unspecifiedSvg, domesticPkk: unspecifiedPkkSvg, foreign: unspecifiedForSvg, foreignPkk: unspecifiedForPkkSvg },
		{ type: "special", domestic: specialSvg, domesticPkk: specialPkkSvg, foreign: specialForSvg, foreignPkk: specialForPkkSvg },
		{ type: "wing", domestic: wingSvg, domesticPkk: wingPkkSvg, foreign: wingForSvg, foreignPkk: wingForPkkSvg },
		{ type: "bst", domestic: bstSvg, domesticPkk: bstPkkSvg, foreign: bstForSvg, foreignPkk: bstForPkkSvg },
	]

  const vesselType = shipType.toLowerCase();
	for (let i = 0; i < shipTypes.length; i++) {
		const el = shipTypes[i];
		if (vesselType.includes(el.type)) {
			if (isIndonesia) {
				if (isPkk) return el.domesticPkk
				return el.domestic
			}
			if (isPkk) return el.foreignPkk
			return el.foreign
		}
	}
  return shipIcon;
}