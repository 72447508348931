export {default as arrowUpSvg} from "./arrow-up.svg";
export {default as arrowUpBlendSvg} from "./arrow-up-blend.svg";
export {default as arrowLeftSvg} from "./arrow-left.svg";
export {default as arrowLeftBlendSvg} from "./arrow-left-blend.svg";
export {default as arrowRightSvg} from "./arrow-right.svg";
export {default as arrowRightBlendSvg} from "./arrow-right-blend.svg";
export {default as arrowDownSvg} from "./arrow-down.svg";
export {default as arrowDownBlendSvg} from "./arrow-down-blend.svg";
export {default as clockSvg} from "./clock.svg";
export {default as contrastSvg} from "./contrast.svg";
export {default as colorCheckSquareSvg} from "./color-check-square.svg";
export {default as colorXSquareSvg} from "./color-x-mark-square.svg";
export {default as barLineChartSvg} from "./bar-line-chart.svg";
export {default as barChartGraphSvg} from "./bar-chart-graph.svg";
export {default as buildingSvg} from "./building.svg";
export {default as cameraSvg} from "./camera.svg";
export {default as checkSvg} from "./check.svg";
export {default as chevronDownSvg} from "./chevron-down.svg"
export {default as chevronUpDoubleSvg} from "./chevron-up-double.svg";
export {default as chevronDownDoubleSvg} from "./chevron-down-double.svg";
export {default as circleInfoSvg} from "./circle-info.svg";
export {default as clockFastForwardSvg} from "./clock-fast-forward.svg";
export {default as enrouteNavigationSvg} from "./enroute-navigation.svg";
export {default as eyeSvg} from "./eye.svg";
export {default as eyeDetailSvg} from "./eye-detail.svg";
export {default as eyeSlashSvg} from "./eyeSlash.svg";
export {default as fileSvg} from "./file.svg";
export {default as fileReportSvg} from "./file-report.svg";
export {default as flaggedPointsSvg} from "./flagged-point.svg";
export {default as gearRegSvg} from "./gear-reg.svg";
export {default as gridSvg} from "./grid.svg";
export {default as handSvg} from "./hand.svg";
export {default as handBlendSvg} from "./hand-blend.svg";
export {default as helicopterSvg} from "./helicopter.svg";
export {default as hexagonLineSvg} from "./hexagon-line.svg";
export {default as homePageSvg} from "./homepage.svg";
export {default as hourglassSvg} from "./hourglass.svg";
export {default as lineChartUpSvg} from "./line-chart-up-03.svg";
export {default as lineChartUp2Svg} from "./line-chart-up-02.svg";
export {default as listSvg} from "./list.svg";
export {default as locationRedSvg} from "./location-red.svg";
export {default as logoutSvg} from "./logout.svg";
export {default as markerBuildingSvg} from "./marker-building.svg";
export {default as markerCommunicationSvg} from "./marker-communication.svg";
export {default as markerDefaultSvg} from "./marker-default.svg";
export {default as markerEnrouteSvg} from "./marker-enroute.svg";
export {default as markerGenericSvg} from "./marker-generic.svg";
export {default as markerHeliportSvg} from "./marker-heliport.svg";
export {default as markerOffshoreSvg} from "./marker-offshore.svg";
export {default as markerPinSvg } from "./marker-pin.svg";
export {default as markerRadioSvg} from "./marker-radio.svg";
export {default as menuBarsSvg} from "./menu-bars.svg";
export {default as menuBarsBlendSvg} from "./menu-bars-blend.svg";
export {default as minusSvg} from "./minus.svg";
export {default as minusBlendSvg} from "./minus-blend.svg";
export {default as offshoreSvg} from "./offshore.svg";
export {default as outlineCircleInfoSvg} from "./outline-circle-info.svg";
export {default as phoneCallSvg} from "./phone-call.svg";
export {default as pieChartSvg} from "./pie-chart.svg";
export {default as pinSvg} from "./pin.svg";
export {default as plusSvg} from "./plus.svg";
export {default as plusBlendSvg} from "./plus-blend.svg";
export {default as pointerSvg} from "./pointer.svg";
export {default as pointerBlendSvg} from "./pointer-blend.svg";
export {default as refreshSvg} from "./refresh.svg";
export {default as refreshBlendSvg} from "./refresh-blend.svg"
export {default as shareSvg} from "./share.svg";
export {default as shipSvg} from "./ship.svg";
export {default as signalSvg} from "./signal.svg";
export {default as signalTowerSvg} from "./signal-tower.svg";
export {default as stopSvg} from "./stop.svg";
export {default as stopBlendSvg} from "./stop-blend.svg";
export {default as sunSvg} from "./sun.svg";
export {default as sunCogSvg} from "./sun-cog.svg";
export {default as sunCogBlendSvg} from "./sun-cog-blend.svg";
export {default as tableSvg} from "./table.svg";
export {default as target2Svg} from "./target-2.svg";
export {default as trendDownSvg} from "./trend-down.svg";
export {default as trendUpSvg} from "./trend-up.svg";
export {default as usersSvg} from "./users.svg";
export {default as xMarkSvg} from "./x-mark.svg";
export {default as xMarkBlendSvg} from "./x-mark-blend.svg";
export {default as wirelessSvg} from "./wireless.svg";
export {default as yellowCameraSvg} from "./yellow-camera.svg";
export {default as yellowClipSvg} from "./yellow-clip.svg";
export {default as yellowEditPencilSvg} from "./yellow-edit-pencil.svg";
export {default as yellowEyeSvg} from "./yellow-eye.svg";
export {default as yellowHomepageSvg} from "./yellow-homepage.svg";
export {default as yellowSignalSvg} from "./yellow-signal.svg";
export {default as yellowTableSvg} from "./yellow-table.svg";
export {default as yellowTrashSvg} from "./yellow-trash.svg";
export {default as yellowDownSvg} from "./yellow-down.svg";
export {default as yellowUpSvg} from "./yellow-up.svg";
export {default as lockSvg} from "./lock.svg";

// SHIP
export {
  bstSvg,
  bstPkkSvg,
  bstForSvg,
  bstForPkkSvg,
  cargoSvg,
  cargoPkkSvg,
  cargoForSvg,
  cargoForPkkSvg,
  fishingSvg,
  fishingPkkSvg,
  fishingForSvg,
  fishingForPkkSvg,
  highSpeedSvg,
  highSpeedPkkSvg,
  highSpeedForSvg,
  highSpeedForPkkSvg,
  militarySvg,
  militaryPkkSvg,
  militaryForSvg,
  militaryForPkkSvg,
  motionLessSvg,
  motionLessForSvg,
  passengerSvg,
  passengerPkkSvg,
  passengerForSvg,
  passengerForPkkSvg,
  rescueSvg,
  rescuePkkSvg,
  rescueForSvg,
  rescueForPkkSvg,
  specialSvg,
  specialPkkSvg,
  specialForSvg,
  specialForPkkSvg,
  tankerSvg,
  tankerPkkSvg,
  tankerForSvg,
  tankerForPkkSvg,
  towingSvg,
  towingPkkSvg,
  towingForSvg,
  towingForPkkSvg,
  tugSvg,
  tugPkkSvg,
  tugForSvg,
  tugForPkkSvg,
  unspecifiedSvg,
  unspecifiedPkkSvg,
  unspecifiedForSvg,
  unspecifiedForPkkSvg,
  wingSvg,
  wingPkkSvg,
  wingForSvg,
  wingForPkkSvg
} from "./ship"