import { createContext, useContext, useState } from "react";

interface ISoundContext {
  sounds: any[];
  setSounds: (val: any) => void; 
}

export const SoundContext = createContext<ISoundContext>({
  sounds: [],
  setSounds: (val: []) => {}
})

export const useSoundContext = () => {
  return useContext(SoundContext)
}

export function SoundContextProvider({ children }: { children: JSX.Element}) {
  const [sounds, setSounds] = useState<any[]>([])

  return (
    <SoundContext.Provider
      value={{
        sounds,
        setSounds
      }}
    >
      { children }
    </SoundContext.Provider>
  )
}