import React, { forwardRef, useCallback, useState } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import { makeStyles } from "@mui/styles";
import { Card, CardActions, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material"
import { outlineCircleInfoSvg } from "./icon/new-icons";
import { useSoundContext } from "../contexts/soundContext";

const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      minWidth: "344px !important"
    }
  },
  card: {
    marginRight: "30px",
    width: "100%",
    backgroundColor: "#262626"
  },
  typography: {
    color: "#F4F4F4"
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between",
    borderLeft: "4px solid #0F62FE"
  },
  icons: {
    marginLeft: "auto"
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    color: "#F4F4F4",
    transition: "all .2s"
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4
  },
  button: {
    padding: 0,
    textTransform: "none"
  }
}));

interface GeofenceInfoProps extends CustomContentProps {
  allowDownload?: boolean;
}

const GeofenceInfoNotif = forwardRef<HTMLDivElement, GeofenceInfoProps>(
  ({ id, ...props }, ref) => {
  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();
  const { sounds, setSounds } = useSoundContext()
  
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
    if (sounds.length) {
      sounds.map(el => el.stop())
      setSounds([])
    }
  }, [id, closeSnackbar, sounds]);

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardActions classes={{ root: classes.actionRoot }}>
          <img src={outlineCircleInfoSvg} alt="info" />
          <Typography variant="body2" className={classes.typography}>
            {props.message}
          </Typography>
          <div className={classes.icons}>
            <IconButton
              size="small"
              className={classes.expand}
              onClick={handleDismiss}
            >
              <Close fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
      </Card>
    </SnackbarContent>
  )
})

GeofenceInfoNotif.displayName = "GeofenceInfoNotif"

export default GeofenceInfoNotif;
