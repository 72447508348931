import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import { SnackbarProvider } from 'notistack';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import GeofenceNotif from './components/GeofenceNotif';
import GeofenceInfoNotif from './components/GeofenceInfo';
import { SoundContextProvider } from './contexts/soundContext';

declare module "notistack" {
	interface VariantOverrides {
		geofenceNotif: true;
		geofenceInfo: true;
		geofenceData: {         
			data: any  
		}
	}
}

const children = (
	<AuthContextProvider>
		<ThemeContextProvider>
			<SoundContextProvider>
				<Router>
					{/* <React.StrictMode> */}
						<SnackbarProvider maxSnack={10} Components={{
							geofenceNotif: GeofenceNotif,
							geofenceInfo: GeofenceInfoNotif
						}}>
							<App />
						</SnackbarProvider>
					{/* </React.StrictMode> */}
				</Router>
			</SoundContextProvider>
		</ThemeContextProvider>
	</AuthContextProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
