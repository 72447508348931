export {default as bstSvg} from "./bst.svg";
export {default as bstPkkSvg} from "./bst-pkk.svg";
export {default as bstForSvg} from "./bst-for.svg";
export {default as bstForPkkSvg} from "./bst-for-pkk.svg";
export {default as cargoSvg} from "./cargo.svg";
export {default as cargoPkkSvg} from "./cargo-pkk.svg";
export {default as cargoForSvg} from "./cargo-for.svg";
export {default as cargoForPkkSvg} from "./cargo-for-pkk.svg";
export {default as fishingSvg} from "./fishing.svg";
export {default as fishingPkkSvg} from "./fishing-pkk.svg";
export {default as fishingForSvg} from "./fishing-for.svg";
export {default as fishingForPkkSvg} from "./fishing-for-pkk.svg";
export {default as highSpeedSvg} from "./highspeed.svg";
export {default as highSpeedPkkSvg} from "./highspeed-pkk.svg";
export {default as highSpeedForSvg} from "./highspeed-for.svg";
export {default as highSpeedForPkkSvg} from "./highspeed-for-pkk.svg";
export {default as militarySvg} from "./military.svg";
export {default as militaryPkkSvg} from "./military-pkk.svg";
export {default as militaryForSvg} from "./military-for.svg";
export {default as militaryForPkkSvg} from "./military-for-pkk.svg";
export {default as motionLessSvg} from "./motionless.svg";
export {default as motionLessForSvg} from "./motionless-for.svg"
export {default as passengerSvg} from "./passenger.svg";
export {default as passengerPkkSvg} from "./passenger-pkk.svg";
export {default as passengerForSvg} from "./passenger-for.svg";
export {default as passengerForPkkSvg} from "./passenger-for-pkk.svg";
export {default as rescueSvg} from "./rescue.svg";
export {default as rescuePkkSvg} from "./rescue-pkk.svg";
export {default as rescueForSvg} from "./rescue-for.svg";
export {default as rescueForPkkSvg} from "./rescue-for-pkk.svg";
export {default as specialSvg} from "./special.svg";
export {default as specialPkkSvg} from "./special-pkk.svg";
export {default as specialForSvg} from "./special-for.svg";
export {default as specialForPkkSvg} from "./special-for-pkk.svg";
export {default as tankerSvg} from "./tanker.svg";
export {default as tankerPkkSvg} from "./tanker-pkk.svg";
export {default as tankerForSvg} from "./tanker-for.svg";
export {default as tankerForPkkSvg} from "./tanker-for-pkk.svg";
export {default as towingSvg} from "./towing.svg";
export {default as towingPkkSvg} from "./towing-pkk.svg";
export {default as towingForSvg} from "./towing-for.svg";
export {default as towingForPkkSvg} from "./towing-for-pkk.svg";
export {default as tugSvg} from "./tug.svg";
export {default as tugPkkSvg} from "./tug-pkk.svg";
export {default as tugForSvg} from "./tug-for.svg";
export {default as tugForPkkSvg} from "./tug-for-pkk.svg";
export {default as unspecifiedSvg} from "./unspecified.svg";
export {default as unspecifiedPkkSvg} from "./unspecified-pkk.svg";
export {default as unspecifiedForSvg} from "./unspecified-for.svg";
export {default as unspecifiedForPkkSvg} from "./unspecified-for-pkk.svg";
export {default as wingSvg} from "./wing.svg";
export {default as wingPkkSvg} from "./wing-pkk.svg";
export {default as wingForSvg} from "./wing-for.svg";
export {default as wingForPkkSvg} from "./wing-for-pkk.svg";
